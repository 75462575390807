/* div {
    border: 1px solid red;
} */

.contactContainer {
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
    height: 100vh;
    width: 100%;
    background-color: rgb(158, 165, 173);
    border: 2px solid rgb(220, 223, 226);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.contact-body {
    height: calc(100vh - 100px);
}

.contactContainerInsideFolder {
    overflow: scroll;
    height: 85vh;
    width: 100%;
    border-radius: 5px;
    background-color: rgb(158, 165, 173);
    border: 2px solid rgb(220, 223, 226);
    box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -webkit-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
    -moz-box-shadow: -4px 6px 6px -1px rgba(10, 10, 10, 0.59);
}

.mailContainer {
    background-color:  #b3b3b3;
    height: 69vh;
    border-radius: 6px;
    margin: 40px 0 40px 5px !important;
}
.viewmailContainer {
    background-color:  hsl(0, 0%, 70%);
    height: 75vh;
    border-radius: 6px;
    margin: 40px 0 40px 5px !important;
}
.con-hdr {
    height: 60px;
    padding: 12px 0 !important;
    background-color: lightgray;
}

.box {
    border: 1px solid gray;
    background-color: azure;
}

.inboxItem {
    background-color: rgb(179, 179, 179);
    border-radius: 5px;
    color: black;
    cursor: pointer;
}

.leftNavSelected {
    border-top: 1px solid lightgray;
    height: 7vh;
}
.attachment{
    width:4rem;
    cursor:pointer;
}

.leftNavUnselected {
    border-top: 1px solid lightgray;
    height: 7vh;
}

.leftNavUnselectedNoTop {
    border-top: 1px solid lightgray;
    height: 7vh;
}

.spinnerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.spinnerInside {
    margin-left: 45%;
    margin-top: 300px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 4px;
}
  
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(1, 17, 4, 0.5);
    box-shadow: 0 0 1px rgba(100, 96, 96, 0.5);
}

.contact-left-navitem {
    border-top: 1px solid lightgray;
}

.btn-new-msg.row {
    margin-bottom: 10px !important;
}

.no-border-top {
    border-top: none !important;
}

.new-message-title {
    padding: 5px 15px 5px 20px;
}

.mail-to {
    padding-top: 15px !important;
}

textarea:focus-visible, input:focus-visible {
    outline: none;
}
.inputbox{
    width: calc(100% - 71px);
    border: 1px solid lightgray;
    color:#c2bfbf;
    padding-left:5px
}
@media screen and (min-width:768px) {
    .contact-menu-icon {
        visibility: hidden;
    }

    .contact-left-navitem {

        height: 8vh;

    }
 
}
@media screen and (max-width:600px) {
    .new-message-title {
        padding: 15px 0px 10px 0;
    }
    .col-sm-12{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
    .mailContainer {
        height: 85vh;
        margin: 0 !important;
        border-radius: 0;
    }
    .viewmailContainer {
        height: 85vh;
        margin: 0 !important;
        border-radius: 0;
    }
    button.close {
        margin-right: 0 !important;
    }
    .mail-to {
        padding-top: 15px !important;
    }

    .btn-back {
        padding-top: 20px !important;
        height: 90px !important;
    }
}

