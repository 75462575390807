body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.skip-links a {
  position: absolute;
  left: -9999px;
  top: -9999px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -9999;
}

.skip-links a:focus {
  position: fixed; /* Use fixed to keep the link in viewport when focused */
  left: 10px; /* Adjust to desired location */
  top: 10px; /* Adjust to desired location */
  width: auto;
  height: auto;
  overflow: visible;
  z-index: 9999;
  background-color: #fff;
  padding: 10px;
  border: 1px solid #000;
}

